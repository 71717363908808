var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"__main_card_container"},[_c('div',{staticClass:"_main_card_header"},[_c('div',{staticClass:"__header_inner admin_header_padding"},[_c('h2',{staticStyle:{"min-width":"fit-content","padding-right":"10px"}},[_vm._v("User List")]),_c('div',{staticClass:"side_nav_wrapper",staticStyle:{"overflow-x":"auto","min-height":"3rem"}},[_c('div',{staticClass:"__side_nav"},[_c('button',{staticClass:"btn",class:[
              _vm.$route.path == '/admin/users/all' ? '__side_item_active' : '',
            ],on:{"click":function($event){_vm.$router.replace('/admin/users/all').catch((err) => {})}}},[_vm._v(" All ("+_vm._s(_vm.users)+") ")]),(!_vm.disallowedUsers)?_c('button',{staticClass:"btn",class:[
              _vm.$route.path.includes('/userSegments')
                ? '__side_item_active'
                : '',
            ],on:{"click":function($event){_vm.$router
                .replace('/admin/users/userSegments/goldusers')
                .catch((err) => {})}}},[_vm._v(" User Segments ")]):_vm._e(),_c('button',{staticClass:"btn",class:[
              _vm.$route.path == '/admin/users/corporate'
                ? '__side_item_active'
                : '',
            ],on:{"click":function($event){_vm.$router.replace('/admin/users/corporate').catch((err) => {})}}},[_vm._v(" Corporate ("+_vm._s(_vm.corporateUsers)+") ")]),_c('button',{staticClass:"btn",class:[
              _vm.$route.path == '/admin/users/individual'
                ? '__side_item_active'
                : '',
            ],on:{"click":function($event){_vm.$router.replace('/admin/users/individual').catch((err) => {})}}},[_vm._v(" Individual ("+_vm._s(_vm.individualUsers)+") ")]),(!_vm.disallowedUsers)?_c('button',{staticClass:"btn",class:[
              _vm.$route.path == '/admin/users/fraudulent'
                ? '__side_item_active'
                : '',
            ],on:{"click":function($event){_vm.$router.replace('/admin/users/fraudulent').catch((err) => {})}}},[_vm._v(" Tagged ("+_vm._s(_vm.fraudulentUsers)+") ")]):_vm._e(),_c('button',{staticClass:"btn",class:[
              _vm.$route.path == '/admin/users/inactive'
                ? '__side_item_active'
                : '',
            ],on:{"click":function($event){_vm.$router.replace('/admin/users/inactive').catch((err) => {})}}},[_vm._v(" Inactive ("+_vm._s(_vm.inactiveUsers)+") ")]),(!_vm.disallowedUsers)?_c('button',{staticClass:"btn",class:[
              _vm.$route.path == '/admin/users/new-user'
                ? '__side_item_active'
                : '',
            ],on:{"click":function($event){_vm.$router.replace('/admin/users/new-users').catch((err) => {})}}},[_vm._v(" New Users ("+_vm._s(_vm.newUsers)+") ")]):_vm._e()]),_c('el-button',{staticClass:"__export",attrs:{"disabled":_vm.disableExport,"type":"primary","loading":_vm.exportLoading},on:{"click":_vm.importFiles}},[_vm._v(" export ")])],1)])]),_c('div',{staticClass:"main_card_body"},[_c('router-view')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }