<template>
  <div class="__main_card_container">
    <div class="_main_card_header">
      <div class="__header_inner admin_header_padding">
        <h2 style="min-width: fit-content; padding-right: 10px">User List</h2>
        <div
          class="side_nav_wrapper"
          style="overflow-x: auto; min-height: 3rem">
          <div class="__side_nav">
            <button
              class="btn"
              :class="[
                $route.path == '/admin/users/all' ? '__side_item_active' : '',
              ]"
              @click="$router.replace('/admin/users/all').catch((err) => {})">
              All ({{ users }})
            </button>
            <button
              class="btn"
              :class="[
                $route.path.includes('/userSegments')
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router
                  .replace('/admin/users/userSegments/goldusers')
                  .catch((err) => {})
              "
              v-if="!disallowedUsers">
              User Segments
            </button>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/users/corporate'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router.replace('/admin/users/corporate').catch((err) => {})
              ">
              Corporate ({{ corporateUsers }})
            </button>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/users/individual'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router.replace('/admin/users/individual').catch((err) => {})
              ">
              Individual ({{ individualUsers }})
            </button>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/users/fraudulent'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router.replace('/admin/users/fraudulent').catch((err) => {})
              "
              v-if="!disallowedUsers">
              Tagged ({{ fraudulentUsers }})
            </button>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/users/inactive'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router.replace('/admin/users/inactive').catch((err) => {})
              ">
              Inactive ({{ inactiveUsers }})
            </button>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/users/new-user'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router.replace('/admin/users/new-users').catch((err) => {})
              "
              v-if="!disallowedUsers">
              New Users ({{ newUsers }})
            </button>
            <!-- <button class="btn" :class="[$route.path == '/admin/users/new-user' ? '__side_item_active' : '']"
                            @click="$router.replace('/admin/users/new-users').catch(err => { })">
                            Open Sender ID Users ({{ newUsers }})
                        </button> -->
          </div>
          <el-button
            :disabled="disableExport"
            type="primary"
            :loading="exportLoading"
            @click="importFiles"
            class="__export">
            export
          </el-button>
        </div>
      </div>
    </div>
    <div class="main_card_body">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
  import store from "@/state/store.js";
  import downloadcsv from "../../../helpers/downloadcsv";
  import UserSegments from "./userSegments.vue";
  export default {
    name: "users",
    components: {
      UserSegments,
    },
    data() {
      return {
        exportLoading: false,
        to: "",
        from: "",
      };
    },
    methods: {
      importFiles() {
        this.exportLoading = true;
        let id = "";
        let currentRoute = this.$router.currentRoute.path;
        if (currentRoute == "/admin/users/all") {
          id = "0";
        } else if (currentRoute == "/admin/users/corporate") {
          id = "1";
        } else if (currentRoute == "/admin/users/individual") {
          id = "2";
        } else if (currentRoute == "/admin/users/fraudulent") {
          id = "3";
        } else if (currentRoute == "/admin/users/inactive") {
          id = "4";
        } else if (currentRoute == "/admin/users/new-users") {
          id = "5";
        } else if (currentRoute == "/admin/users/userSegments/GoldUsers") {
          id = "6";
        } else if (currentRoute == "/admin/users/userSegments/SilverUsers") {
          id = "7";
        } else if (currentRoute == "/admin/users/userSegments/BronzeUsers") {
          id = "8";
        }

        if (id == 5 && this.disableExport == false) {
          let payload = {
            id,
            to: this.to,
            from: this.from,
          };
          store
            .dispatch("admin/users/exportNewUsers", payload)
            .then((res) => {
              downloadcsv(
                res.data,
                `Users_${
                  currentRoute.split("/")[3].charAt(0).toUpperCase() +
                  currentRoute.split("/")[3].slice(1)
                }`
              );
            })
            .finally(() => {
              this.exportLoading = false;
            });
          return;
        }

        store
          .dispatch("admin/users/importFiles", id)
          .then((res) => {
            downloadcsv(
              res.data,
              `Users_${
                currentRoute.split("/")[3].charAt(0).toUpperCase() +
                currentRoute.split("/")[3].slice(1)
              }`
            );
          })
          .finally(() => {
            this.exportLoading = false;
          });
      },
    },
    created() {
      store.dispatch("admin/users/getInactiveUsers");
      store.dispatch("admin/users/allUsers");
      // store.dispatch('admin/users/getUserSegments',1)
      store.dispatch("admin/users/getCorporateUsers", 1);
      store.dispatch("admin/users/getSmeUsers");
      store.dispatch("admin/users/getIndividualUsers");
      store.dispatch("admin/users/getFraudulentUsers");
      store.dispatch("admin/users/getNewUsers", {
        page: 1,
      });
      if (this.$router.currentRoute.path == "/admin/users/new-users") {
        store.dispatch("admin/users/setNewUsersToAndFrom", {
          to: "",
          from: "",
        });
      }
    },
    computed: {
      users() {
        // check if data has key total
        if (store.getters["admin/users/users"].hasOwnProperty("total")) {
          return store.getters["admin/users/users"].total;
        }
        if (store.getters["admin/users/users"].hasOwnProperty("data")) {
          return store.getters["admin/users/users"].data.total_count;
        }
      },
      inactiveUsers() {
        return store.getters["admin/users/inAciveUsers"].length;
      },
      corporateUsers() {
        if (
          store.getters["admin/users/corporateUsers"].hasOwnProperty("total")
        ) {
          return store.getters["admin/users/corporateUsers"].total;
        }
        if (
          store.getters["admin/users/corporateUsers"].hasOwnProperty("data")
        ) {
          return store.getters["admin/users/corporateUsers"].data.total_count;
        }
      },
      smeUsers() {
        if (store.getters["admin/users/smeUsers"].hasOwnProperty("total")) {
          return store.getters["admin/users/smeUsers"].total;
        }
        if (store.getters["admin/users/smeUsers"].hasOwnProperty("data")) {
          return store.getters["admin/users/smeUsers"].data.total_count;
        }
      },
      GoldUsersN() {
        if (store.getters["admin/users/userSegments"].hasOwnProperty("total")) {
          return store.getters["admin/users/userSegments"].total;
        }
        if (store.getters["admin/users/userSegments"].hasOwnProperty("data")) {
          return store.getters["admin/users/userSegments"].data.total;
        }
      },
      individualUsers() {
        if (
          store.getters["admin/users/individualUsers"].hasOwnProperty("total")
        ) {
          return store.getters["admin/users/individualUsers"].total;
        }
        if (
          store.getters["admin/users/individualUsers"].hasOwnProperty("data")
        ) {
          return store.getters["admin/users/individualUsers"].data.total_count;
        }
      },
      fraudulentUsers() {
        return store.getters["admin/users/fraudulentUsers"].length;
      },
      newUsers() {
        return store.getters["admin/users/newUsers"].length;
      },
      disableExport() {
        if (this.$router.currentRoute.path == "/admin/users/new-users") {
          const { to, from } =
            store.getters["admin/users/getNewUsersFromAndTo"];
          if (to && from) {
            this.to = to;
            this.from = from;
            return false;
          }
          return true;
        }
        return false;
      },
      disallowedUsers() {
        return this.$store.getters["auth/disallowedUsers"];
      },
    },
  };
</script>
<style scoped>
  .__export:disabled {
    background-color: #e4e4e4 !important;
    color: #8e8e8e !important;
    border-color: #e4e4e4;
  }

  .btn {
    width: max-content;
  }
</style>
